import { createApp } from 'vue'

import routes from './router'
import RootPage from './pages/Wrapper.vue'
import { createRouter, createWebHistory } from 'vue-router'

console.info('RootPage', RootPage)
const myAapp = createApp(RootPage)
console.info('myAapp', myAapp)
const app = () => {
  const router = createRouter({
    routes,
    history: createWebHistory()
  })
  myAapp.use(router)
  myAapp.mount('#app')
}
app()
