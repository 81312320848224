import {instance} from '../axios-instance';
import Cookies from 'js-cookie'
import {projectSetter} from '@store/projects'
import qs from "qs";

const path = {
  registration: '/auth/register',
  login: '/auth/login',
  sendVerificationEmail: '/auth/send-verification-email',
  myInfo: '/users/me',
  verifyEmail: '/auth/verify-email',
  myCompany: '/companies/get',
  forgotPassword: '/auth/forgot-password',
  archivePhotos: '/archive-photos',
  archiveVideos: '/archive-videos',
  refreshTokens: '/auth/refresh-tokens',
  projectsGet: '/projects/get',
  projectsList: '/projects/get_list',
  sendCompanyFile: '/companies/upload',
  createCompany: '/companies/create',
  updateCompany: '/companies/update',
  setNewPassword: '/auth/reset-password',
  projectUpload: '/projects/upload',
  projectCreate: '/projects/create',
  projectUpdate: '/projects/update',
  nominationsList: '/nominations',
  photosYears: '/archive-photos/years',
  videoYears: '/archive-videos/years',
  projectsListRus: 'https://dhba.mysuperproduction.ru/v1/projects/get_list',
  getJury: '/archive-juries',
  getExperts: '/archive-experts',
  getPartners: '/partners',
  genProjectToken: '/projects/info-token',
  getProjectInfoByToken: '/projects/info'
}

export const getPhotosYears = () => {
  return new Promise((resolve, reject) => {
    instance.get(path.photosYears).then((response) => {
      resolve(response.data)
    })
  })
}

export const getVideosYears = () => {
  return new Promise((resolve, reject) => {
    instance.get(path.videoYears).then((response) => {
      resolve(response.data)
    })
  })
}

export const getNominationsList = (year) => {
  return new Promise((resolve, reject) => {
    if (year) {
      instance({
        method: 'get',
        url: path.nominationsList+`?filter=%7B%22years%22:%22${year}%22%7D`,
      }).then((response) => {
        resolve(response.data)
      })
    } else {
      instance.get(path.nominationsList).then((response) => {
        resolve(response.data)
      })
    }

  })
}
export const archivePhotos = (year, range) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      // params: {
      //   filter: encodeURI(`{"year":"${year}"}`),
      //   range: '[' + range + ',' + (+range + 99) + ']',
      // },
      url: path.archivePhotos+`?filter=%7B%22year%22:%22${year}%22%7D&range=[${range},${range+99}]`,
    }).then((response) => {
      resolve(response.data)
    })
  })
}
export const archiveVideos = (year, range) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',

      url: path.archiveVideos+`?filter=%7B%22year%22:%22${year}%22%7D&range=[${range},${range+99}]`,
    }).then((response) => {
      resolve(response.data)
    })
  })
}
export const registration = (data) => {
  return new Promise((resolve, reject) => {
    instance.post(path.registration, data).then((response) => {
      if (response?.data?.tokens?.refresh) {
        Cookies.set('token', response?.data?.tokens?.refresh?.token)
        Cookies.set('access-token', response?.data?.tokens?.access?.token)
        localStorage.setItem('user', JSON.stringify(response.data.user))
      }
      resolve(response.data)
    }).catch((response) => {
      reject(response.data)
    })
  })
}
export const login = (data) => {
  return new Promise((resolve, reject) => {
    instance.post(path.login, data).then((response) => {
      Cookies.set('token', response?.data?.tokens?.refresh?.token)
      Cookies.set('access-token', response?.data?.tokens?.access?.token)
      localStorage.setItem('user', JSON.stringify(response.data.user))
      setTimeout(() => {
        resolve(response.data)
      }, 50)
    }).catch(() => {
      reject()
    })
  })
}

export const setNewPassword = (data, token) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url: path.setNewPassword,
      data: data,
      params: {token},
    }).then((data) => {
      resolve(data)
    })
  })
}
export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    instance.post(path.forgotPassword, data).then((response) => {
      resolve(response.data,)
    }).catch(() => {
      reject()
    })
  })
}
export const refreshTokens = (data) => {
  return new Promise((resolve, reject) => {
    instance.post(path.refreshTokens, data).then((response) => {
      resolve(response.data,)
    }).catch(() => {
      reject()
    })
  })
}
export const projectsList = (year, howMuch, region) => {
  return new Promise((resolve, reject) => {
    const years = ['2022', '2021', '2020', '2019', '2018', '2017', '2016'];
    const data = {}
    howMuch = howMuch || 1000
    console.info('encodeURI(`{"year":"${year}"}`)',encodeURI(`{"year":"${year}"}`))
    instance({
      method: 'get',

      headers: {
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
      url: region == 'rus' ? path.projectsListRus : path.projectsList +`?filter=%7B%22year%22:%22${year}%22%7D&range=[0,${howMuch}]`,
    }).then((res) => {
      resolve(res.data)
      projectSetter.setResponseFlag(true)
    })
  })
}
export const extendProjectList = (year, range, region) => {
  return new Promise((resolve, reject) => {
    const rangeStart = range
    const rangeEnd = +rangeStart + 99
    instance({
      method: 'get',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
      url: (region == 'rus' ? path.projectsListRus : path.projectsList) + `?filter=%7B%22year%22%3A%22${year}%22%7D&range=%5B${rangeStart}%2C${rangeEnd}%5D`,
    }).then((res) => {
      resolve(res.data)
    })
  })
}
export const sendCompanyFile = (formData, query) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      params: {...query},
      url: path.sendCompanyFile,
      data: formData,

      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
        'Content-Type': 'multipart/form-data',

      },
    }).then((data) => {
      resolve(data.data.file)
    })
  })
}
export const projectUpload = (formData, query) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      params: {...query},
      url: path.projectUpload,
      data: formData,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
        'Content-Type': 'multipart/form-data',

      },
    }).then((data) => {
      resolve(data)
    })
  })
}
export const projectCreate = (formData) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url: path.projectCreate,
      data: formData,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
    }).then((data) => {
      resolve(data)
    })
  })
}
export const projectUpdate = (formData) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url: path.projectUpdate,
      data: formData,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
    }).then((data) => {
      resolve(data)
    })
  })
}
export const projectsGet = () => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url: path.projectsGet,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
    }).then((data) => {
      resolve(data)
    }).catch((e) => {
      reject()
    })
  })
}
export const createCompany = (data) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url: path.createCompany,
      data: data,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
    }).then((data) => {
      resolve(data)
    })
  })
}
export const updateCompany = (data) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url: path.updateCompany,
      data: data,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
    }).then((data) => {
      resolve(data)
    })
  })
}
export const getMyInfo = () => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url: path.myInfo,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
    }).then((res) => {
      resolve(res.data)
      sessionStorage.setItem('paymentStatus', res.data.company?.paymentStatus)
    })
  })
}
export const getMyCompany = (data) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url: path.myCompany,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
    }).then((res) => {
      resolve(res.data)
    }).catch(() => {
      reject()
    })
  })
}
export const sendVerificationEmail = () => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url: path.sendVerificationEmail,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
      },
    }).then((data) => {
      resolve(data)
    })
  })
}
export const verifyEmail = (token) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url: path.verifyEmail,
      params: {
        token: token
      },
    }).then((data) => {
      resolve(data)
    })
  })
}

export const getJury = async (year) => {
  const response = await instance({
    method: 'get',
    url: path.getJury+`?filter=%7B%22years%22:%22${year}%22%7D`,

  })
  if (response.status == 200) {
    return response.data
  }
}

export const getExperts = async (year) => {
  const response = await instance({
    method: 'get',
    url: path.getExperts+`?filter=%7B%22years%22:%22${year}%22%7D`

  })
  if (response.status == 200) {
    return response.data
  }
}

export const getJuryFull = async () => {
  const response = await instance({
    method: 'get',
    url: path.getJury
  })
  if (response.status == 200) {
    return response.data
  }
}

export const getExpertsFull = async () => {
  const response = await instance({
    method: 'get',
    url: path.getExperts
  })
  if (response.status == 200) {
    return response.data
  }
}

export const getPartners = async (year) => {
  const response = await instance({
    method: 'get',
    url: path.getPartners+`?filter=%7B%22years%22:%22${year}%22%7D`,
  })
  if (response.status == 200) {
    return response.data
  }
}

export const getPartnersMain = async () => {
  const response = await instance({
    method: 'get',
    url: path.getPartners,
    params: {
      range: '[0,1000]'
    },
  })
  if (response.status == 200) {
    return response.data
  }
}

export const genProjectToken = (projectId) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url: path.genProjectToken,
      params: {
        projectId: projectId
      },
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + Cookies.get('access-token'),
        'Content-Type': 'multipart/form-data',

      },
    })
      .then((res) => {
        resolve(res.data.token)
      })
  })
}

export const getProjectInfoByToken = (projectToken) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url: path.getProjectInfoByToken,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'authorization': 'Bearer ' + projectToken,
        'accept': 'application/json',
      }
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        console.info('e.e.e.e', e)
        reject()
      })
  })
}