import axios from 'axios'
import Cookies from 'js-cookie'
// import {instance} from "../axios-instance";
// import { loginMethods } from './sender/login.js';
import { userActions, userGetter } from '@store/user'
import { getProjectInfoByToken } from '@api'
import {
  useRoute,
  createRouter as _createRouter,
  createMemoryHistory,
  createWebHistory
} from 'vue-router'

let lastCheck = 0
let isAuth = false
const mainFooter = () => import('@pages/main/footer.vue')
const topLine = () => import('@pages/main/topLine/index.vue')
const checkToken = function () {
  const dateTime = new Date() - 0
  return new Promise((resolve, reject) => {
    if (Math.abs(lastCheck - dateTime) < 10 * 1000) {
      if (isAuth) {
        resolve()
      } else {
        reject()
      }
    } else {
      const query = new URLSearchParams(window.location.search)
      let token = query.get('token')
      if (!token && localStorage.getItem('token')) {
        token = localStorage.getItem('token')
      }
      if (token) {
        // loginMethods.checkToken(token).then(() => {
        //   isAuth = true;
        //   resolve()
        // }).catch(() => {
        //   reject()
        // })
      } else {
        reject()
      }
    }
  })
}
const routes = [
  {
    path: '/',
    name: 'Main',
    meta: {
      type: 'main',
      title: 'router.meta.title.main'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      banner: () => import('@pages/main/banner.vue'),
      nominations: () => import('@pages/main/nominations/index.vue'),
      projects: () => import('@pages/main/projects/index.vue'),
      system: () => import('@pages/main/system/index.vue'),
      gallery: () => import('@pages/main/gallery.vue'),
      nomineesMain: () => import('@pages/main/nomineesMain/index.vue'),
      // partners: () => import('@pages/main/partners'),
      footer: mainFooter,
      popup: () => import('@pages/main/popup/index.vue')
    }
  },
  {
    path: '/partners',
    name: 'Partners',
    meta: {
      type: 'partners',
      title: 'router.meta.title.partners'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      innerBanner: () => import('@pages/main/innerBanner.vue'),
      partnersPage: () => import('@pages/main/partnersPage/index.vue')
    }
  },
  {
    path: '/photo-gallery',
    name: 'PhotoGallery',
    meta: {
      type: 'photo-gallery',
      title: 'router.meta.title.photo-gallery'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      photogallery: () => import('@pages/main/photogallery/index.vue')
    }
  },
  {
    path: '/jury',
    name: 'Jury',
    meta: {
      type: 'jury'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      innerBanner: () => import('@pages/main/innerBanner.vue'),
      jury: () => import('@pages/main/jury/index.vue')
    }
  },
  {
    path: '/registration',
    name: 'registration',
    meta: {
      type: 'registration'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      registration: () => import('@pages/main/registration/index.vue')
    }
  },
  {
    path: '/registration-company',
    name: 'registration-company',
    meta: {
      type: 'registration'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      registration: () => import('@pages/main/registration/index.vue')
    },
    beforeRouteEnter(to, from, next) {
      if (Cookies.get('access-token') && Cookies.get('token')) {
        next()
      } else {
        next({ name: 'Main' })
      }
    }
  },
  {
    path: '/nominees',
    name: 'nominees',
    meta: {
      type: 'nominees',
      title: 'router.meta.title.nominees'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      nominees: () => import('@pages/main/nominees/index.vue')
    }
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      type: 'account'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      account: () => import('@pages/main/account/index.vue')
    }
  },
  {
    path: '/account/project/create',
    name: 'project-create',
    meta: {
      type: 'account'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      account: () => import('@pages/main/account/index.vue')
    },
    beforeEnter(to, from, next) {
      if (Cookies.get('access-token') && Cookies.get('token')) {
        next()
      } else {
        next({ name: 'Main' })
      }
    }
  },
  {
    path: '/account/project/:projectId',
    name: 'project-show',
    meta: {
      type: 'account'
    },
    beforeEnter(to, from, next) {
      if (Cookies.get('access-token') && Cookies.get('token')) {
        if (to.params?.projectId) {
          let projects = userGetter.projects()
          const projectChecker = () => {
            let projects = userGetter.projects()
            if (projects.items.find(i => i.id == to.params?.projectId)) {
              next()
            } else {
              next({
                name: 'account',
                query: useRoute().query
              })
            }
          }
          if (!projects.isGetted) {
            userActions.getProjects().then(() => {
              setTimeout(() => {
                projectChecker()
              }, 0)
            })
          } else {
            projectChecker()
          }
        } else {
          next({ name: 'account', query: useRoute().query })
        }
      } else {
        next({ name: 'Main' })
      }
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      account: () => import('@pages/main/account/index.vue')
    }
  },
  {
    path: '/account/project/:projectId/edit',
    name: 'project-edit',
    meta: {
      type: 'account'
    },
    beforeEnter(to, from, next) {
      if (Cookies.get('access-token') && Cookies.get('token')) {
        if (to.params?.projectId) {
          let projects = userGetter.projects()
          const projectChecker = () => {
            let projects = userGetter.projects()
            if (projects.items.find(i => i.id == to.params?.projectId)) {
              next()
            } else {
              next({ name: 'account', query: useRoute().query })
            }
          }
          if (!projects.isGetted) {
            userActions.getProjects().then(() => {
              setTimeout(() => {
                projectChecker()
              }, 0)
            })
          } else {
            projectChecker()
          }
        } else {
          next({ name: 'account', query: useRoute().query })
        }
      } else {
        next({ name: 'Main' })
      }
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      account: () => import('@pages/main/account/index.vue')
    }
  },
  {
    path: '/account/createProject',
    name: 'accountProjectCreate',
    meta: {
      type: 'account',
      state: 'projects'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      account: () => import('@pages/main/account/index.vue')
    }
  },
  {
    path: '/account-profile',
    name: 'account-profile',
    meta: {
      type: 'account'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      topLine: topLine,
      account: () => import('@pages/main/account/index.vue')
    }
  },
  {
    path: '/projects/:tokenProject',
    name: 'projectByToken',
    meta: {
      type: 'projects'
    },
    components: {
      header: () => import('@pages/main/header.vue'),
      footer: mainFooter,
      project: () => import('@pages/main/projectPreview/index.vue')
    },
    beforeEnter(to, from, next) {
      console.info(
        "to.params.tokenProject.replace(/dDotT/g, '.')",
        to.params.tokenProject.replace(/dDotT/g, '.')
      )
      userActions
        .setProjectFromToken(to.params.tokenProject.replace(/dDotT/g, '.'))
        .then(id => {
          next({ name: `project-show`, params: { projectId: id } })
        })
        .catch(e => {
          console.info('ununu', e)
          // next({name: 'Main'})
        })
    }
  }
]

export function createRouter() {
  return _createRouter({
    // use appropriate history implementation for server/client
    // import.meta.env.SSR is injected by Vite.
    history: import.meta.env.SSR
      ? createMemoryHistory('/')
      : createWebHistory('/'),
    routes
  })
}

export default routes
