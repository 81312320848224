import { reactive } from 'vue'
import Cookies from 'js-cookie'
import {
  refreshTokens,
  projectsGet,
  getMyCompany,
  login,
  createCompany,
  getNominationsList,
  projectCreate,
  projectUpdate,
  getProjectInfoByToken
} from '@api/index.js'

let store = reactive({
  user: {},
  isPaid: false,
  nominationList: [],
  company: {
    isGetted: false,
    data: {}
  },
  projects: {
    items: [],
    isGetted: false
  },
  statusMap: {
    new: 'lk.user.statusMap.new',
    fixed: 'lk.user.statusMap.fixed',
    declined: 'lk.user.statusMap.declined',
    approved: 'lk.user.statusMap.approved',
    final: 'lk.user.statusMap.final',
    winner: 'lk.user.statusMap.winner'
  },
  registrationStepsLabels: [
    'lk.registrationStepsLabels.step1',
    'lk.registrationStepsLabels.step2',
    'lk.registrationStepsLabels.step3'
  ],
  projectsOptions: [
    {
      name: 'name',
      registrationStep: 0,
      value: 'projectsOptions1.value',
      type: 'Input',
      canEdit: true,
      required: true
    },
    {
      name: 'bookletDescription',
      registrationStep: 0,
      value: 'projectsOptions2.value',
      type: 'Textarea',
      canEdit: true,
      required: true,
      info: 'projectsOptions2.info'
    },
    {
      name: 'mainGoal',
      registrationStep: 0,
      value: 'projectsOptions3.value',
      type: 'Textarea',
      canEdit: true,
      required: false,
      info: 'projectsOptions3.info'
    },
    {
      name: 'nomination',
      registrationStep: 0,
      value: 'projectsOptions4.value',
      viewName: 'nominationId',
      type: 'Select',
      canEdit: projectData => {
        if ('status' in projectData) {
          return projectData.status == 'new' || projectData.status == ''
        }
        return false
      },
      variants: () => {
        return store.nominationList
      },
      required: true
    },
    {
      name: 'reasons',
      registrationStep: 0,
      subName: 'reason',
      value: 'projectsOptions5.value',
      type: 'List',
      subType: 'textarea',
      canEdit: true,
      required: false,
      info: 'projectsOptions5.info'
    },
    {
      name: 'goals',
      registrationStep: 0,
      subName: 'goal',
      value: 'projectsOptions6.value',
      subType: 'textarea',
      type: 'List',
      canEdit: true,
      required: false
    },
    {
      name: 'targetAudience',
      registrationStep: 0,
      value: 'projectsOptions7.value',
      type: 'Textarea',
      canEdit: true,
      required: false,
      info: ''
    },
    {
      name: 'mainIdea',
      registrationStep: 0,
      value: 'projectsOptions8.value',
      type: 'Textarea',
      canEdit: true,
      required: false,
      info: 'projectsOptions8.info'
    },

    {
      registrationStep: 0,
      staticValue: 'projectsOptions9.static.value',
      value: 'projectsOptions9.value',
      placeholder: '',
      type: 'FixedTextLabel'
    },
    {
      name: 'iResearches',
      registrationStep: 0,
      value: 'projectsOptions10.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions10.info'
    },
    {
      name: 'iCommIn',
      registrationStep: 0,
      value: 'projectsOptions11.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions11.info'
    },
    {
      name: 'iCommOut',
      registrationStep: 0,
      value: 'projectsOptions12.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions12.info'
    },
    {
      name: 'iEventOut',
      registrationStep: 0,
      value: 'projectsOptions13.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions13.info'
    },
    {
      name: 'iEventIn',
      registrationStep: 0,
      value: 'projectsOptions14.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions14.info'
    },
    {
      name: 'iTrainings',
      registrationStep: 0,
      value: 'projectsOptions15.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions15.info'
    },
    {
      name: 'iMaterial',
      registrationStep: 0,
      value: 'projectsOptions16.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions16.info'
    },
    {
      name: 'iNonMaterial',
      registrationStep: 0,
      value: 'projectsOptions17.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions17.info'
    },
    {
      name: 'iOther',
      registrationStep: 0,
      value: 'projectsOptions18.value',
      type: 'Textarea',
      grayLabel: true,
      canEdit: true,
      required: false,
      info: 'projectsOptions18.info'
    },

    {
      name: 'resources',
      registrationStep: 0,
      value: 'projectsOptions19.value',
      type: 'Textarea',
      canEdit: true,
      required: false,
      info: 'projectsOptions19.info'
    },

    {
      name: 'plan',
      registrationStep: 0,
      value: 'projectsOptions20.value',
      type: 'Textarea',
      canEdit: true,
      required: false,
      info: 'projectsOptions20.info'
    },
    {
      name: 'results',
      registrationStep: 0,
      subType: 'doubled',
      subNames: ['goal', 'result'],
      subNamesLabel: [
        'projectsOptions21.sublabel1',
        'projectsOptions21.sublabel2'
      ],
      value: 'projectsOptions21.value',
      type: 'List',
      canEdit: true,
      required: false,
      info: 'projectsOptions21.info'
    },
    {
      name: 'presentation',
      value: 'projectsOptions22.value',
      registrationStep: 1,
      type: 'FileInput',
      requiredOnNext: true,
      canEdit: true,
      required: false,
      info: '',
      accept: '.ppt,.pptx,.pdf'
    },
    {
      name: 'video',
      value: 'projectsOptions26.value',
      registrationStep: 1,
      requiredOnNext: true,
      type: 'FileInput',
      canEdit: true,
      required: false,
      accept: '.mp4,.mov,.avi'
    },
    {
      name: 'extra',
      registrationStep: 1,
      value: 'projectsOptions23.value',
      placeholder: '',
      type: 'List',
      canEdit: true,
      accept: '*',
      subNames: ['file', 'name'],
      subType: 'upload',
      required: false,
      info: 'projectsOptions23.info'
    },
    {
      name: 'objectives',
      viewName: 'authors',
      subType: 'text&file',
      requiredOnNext: true,
      registrationStep: 2,
      info: '',
      value: 'projectsOptions23.value',
      placeholder: [
        'projectsOptions24.placeholder1',
        'projectsOptions24.placeholder2'
      ],
      accept: '.jpeg,.png,.jpg,.tiff',
      subNames: [
        { name: 'name', type: 'text', label: 'projectsOptions24.subnames1' },
        {
          name: 'position',
          type: 'text',
          label: 'projectsOptions24.subnames2'
        },
        { name: 'photo', type: 'file', label: 'projectsOptions24.subnames3' }
      ],
      viewNames: [
        { label: 'projectsOptions24.viewname1' },
        { label: 'projectsOptions24.viewname2' },
        { label: 'projectsOptions24.viewname3' }
      ],
      type: 'List',
      canEdit: true
    },
    {
      name: 'contractors',
      registrationStep: 2,
      value: 'projectsOptions27.value',
      type: 'Textarea',
      canEdit: true,
      required: false,
      info: ''
    }
  ]
})

export const userActions = {
  createProject(formData) {
    return new Promise(resolve => {
      projectCreate(formData).then(projectData => {
        userActions.getProjects().then(() => {
          resolve(projectData)
        })
      })
    })
  },
  updateProject(formData) {
    return new Promise(resolve => {
      if ('id' in formData) {
        projectUpdate(formData).then(projectData => {
          userActions.getProjects().then(() => {
            resolve(projectData)
          })
        })
      } else {
        resolve()
      }
    })
  },
  getNominationList() {
    return new Promise((resolve, reject) => {
      getNominationsList().then(data => {
        store.nominationList = data
          .filter(i => i.isActive)
          .map(i => {
            return {
              name: i.name,
              value: i.id + ''
            }
          })
        resolve()
      })
    })
  },
  setProjectFromToken(token) {
    return new Promise((resolve, reject) => {
      getProjectInfoByToken(token)
        .then(res => {
          store.projects.isGetted = true
          store.projects.items = [res.project]
          resolve(res.project.id)
        })
        .catch(e => {
          reject()
        })
    })
  },
  getProjects() {
    return new Promise((resolve, reject) => {
      projectsGet()
        .then(res => {
          store.projects.isGetted = true
          store.projects.items = res.data.projects
          store.projects = { ...store.projects }
          resolve()
        })
        .catch(e => {
          store.projects.isGetted = true
          store.projects.items = []
          resolve()
        })
    })
  },
  getCompany() {
    return new Promise((resolve, reject) => {
      getMyCompany()
        .then(res => {
          store.company.isGetted = true
          store.company.data = res.company
          store.company = { ...store.company }
          resolve()
        })
        .catch(() => {
          store.company.isGetted = true
          resolve()
        })
    })
  },
  createCompany(data) {
    return new Promise((resolve, reject) => {
      createCompany(data)
        .then(() => {
          userActions.getCompany().then(() => {
            resolve()
          })
        })
        .catch(() => {
          reject()
        })
    })
  },
  login(email, password) {
    return new Promise((resolve, reject) => {
      login({
        email: email.toLowerCase(),
        password: password
      })
        .then(data => {
          const promises = [userActions.getProjects(), userActions.getCompany()]
          Promise.all(promises).then(() => {
            store.user = JSON.parse(localStorage.getItem('user'))
            resolve(data)
          })
        })
        .catch(() => {
          reject()
        })
    })
  },
  checkToken() {
    userActions.getNominationList()
    const refreshToken = Cookies.get('token')
    if (refreshToken) {
      refreshTokens({ refreshToken })
        .then(response => {
          store.user = JSON.parse(localStorage.getItem('user'))
          Cookies.set('token', response?.refresh?.token)
          Cookies.set('access-token', response?.access?.token)
          userActions.getCompany()
          userActions.getProjects()
        })
        .catch(() => {
          userActions.exit()
        })
    }
  },
  exit() {
    store.user = {}
    store.projects = {}
    store.isPaid = false
    Cookies.remove('token')
    Cookies.remove('access-token')
  }
}

export const userSetter = {
  setPaid() {
    store.isPaid = true
  }
}

export const userGetter = {
  user: () => store.user,
  isPaid: () => store.isPaid,
  projects: () => store.projects,
  company: () => store.company,
  statusMap: () => store.statusMap,
  projectsOptions: () => store.projectsOptions,
  registrationStepsLabels: () => store.registrationStepsLabels
}
